import { Delete } from '@mui/icons-material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import React from 'react';

import { ColumnIdentifier, HeadCellRenderProps } from '../../../../../components/dataman/types';
import { ContextType } from '../../../../../types';

/**
 * Generate remove context cell
 *
 * @param disabled
 * @param cellIdentifier
 * @param value
 * @param row
 * @param removeContextCallback
 */
export const RenderRemoveContextCell = (
  disabled: boolean,
  cellIdentifier: ColumnIdentifier,
  value: undefined,
  row: ContextType,
  removeContextCallback: any,
): React.FC<HeadCellRenderProps> | JSX.Element => (
  <Box display="flex" justifyContent={'center'}>
    <Button color={'inherit'} onClick={() => removeContextCallback(row.contextId)} disabled={disabled}>
      <Delete></Delete>
    </Button>
  </Box>
);
